import axios from '../../axios-middleware';
import i18n from '@/lang/i18n.js';
import HELPERS from '@/modules/OperatorPanel/helpers';
import Vue from "vue";

const state = {
  operator_panel: {
    isGroupsPanelOpen: true,
    isRightSideBarOpen: false,

    isRightSideBarHistoryPanelOpen: false,
    isRightSideBarHistoryItemId: '',

    isRightSideBarStepsHistoryPanelOpen: false,

		isRightSideBarClientFieldsOpen: false,


    isTopSideBarOpen: false,
    isUserSettingsOpen: false,
    isAddChangeThemeModalOpen: false,
    isNewRoomModalOpen: false,
    isChatNewClientModalOpen: false,
    isChatOpClientModalOpen: false,
    isChatOpClientModalMode: null,
    isDialogSearchModalOpen: false,
    isAddChatUserModalOpen: {
      open: false,
      type: '', // operator, supervisor, transfer
    },
		isCreateTicketModalOpen: false,
		ticketModalAdditionalData: {},

	isTransferChatUserModalOpen: {
		open: false,
		data: null,
		withoutTimer: null,
	},
    langList: [],
    defaultLang: null,
    confirm: {
      open: false,
      title: '',
      text: '',
      cancel: () => console.log('cancel confirm'),
      accept: () => console.log('accept confirm'),
    },
    isCloseBtnClicked: false,
    settings: [],
    settingStylesCss: '',
    settingStylesJS: '',
    clientCardFields: '',
    modalLeaveChatStatus: {
      isOpen: false,
      cb: null,
			open_with_status: null
    },

    isHistoryPanelOpen: false,
    isHistoryPanelRightOpen: false,
		opDarkThemeCookie: false,
		settingFiles: '',
    isTicketLogsModalOpen: false,
  },
};

const mutations = {
  OP_SET_GROUPS_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isGroupsPanelOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarHistoryPanelOpen = isOpen;
  },

  OP_SET_RIGHT_SIDEBAR_HISTORY_ITEM_ID(state, id) {
    state.operator_panel.isRightSideBarHistoryItemId = id;
  },

  OP_SET_RIGHT_SIDEBAR_STEPS_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarStepsHistoryPanelOpen = isOpen;
  },

	OP_SET_RIGHT_SIDEBAR_CLIENT_FIELDS_OPEN(state, isOpen) {
    state.operator_panel.isRightSideBarClientFieldsOpen = isOpen;
  },

  OP_SET_TOP_SIDEBAR_OPEN(state, isOpen) {
    state.operator_panel.isTopSideBarOpen = isOpen;
  },

  OP_SET_USER_SETTINGS_OPEN(state, isOpen) {
    state.operator_panel.isUserSettingsOpen = isOpen;
  },
  OP_SET_HISTORY_PANEL_OPEN(state, isOpen) {
    state.operator_panel.isHistoryPanelOpen = isOpen;
  },

  OP_SET_HISTORY_PANEL_RIGHT_OPEN(state, isOpen) {
    state.operator_panel.isHistoryPanelRightOpen = isOpen;
  },

  OP_SET_ADD_CHAT_USER_OPEN(state, { open, type }) {
    state.operator_panel.isAddChatUserModalOpen = { open, type };
  },

  OP_SET_TRANSFER_CHAT_USER_OPEN(state, { open, data, withoutTimer }) {
	  state.operator_panel.isTransferChatUserModalOpen = { open, data, withoutTimer };
  },

  OP_SET_LANG_LIST(state, langList) {
    state.operator_panel.langList = langList;
  },

  OP_SET_DEFAULT_LANG(state, langId) {
    state.operator_panel.defaultLang = langId;
  },

  OP_SET_CHANGE_THEME_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isAddChangeThemeModalOpen = isOpen;
  },

  OP_SET_DIALOG_SEARCH_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isDialogSearchModalOpen = isOpen;
  },

  OP_SET_NEW_ROOM_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isNewRoomModalOpen = isOpen;
  },

  OP_SET_CHAT_NEW_CLIENT_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isChatNewClientModalOpen = isOpen;
  },

  OP_SET_CHAT_OP_CLIENT_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isChatOpClientModalOpen = isOpen;
  },
	OP_SET_CHAT_OP_CLIENT_MODAL_MODE(state, mode) {
		state.operator_panel.isChatOpClientModalMode = mode;
	},

	OP_SET_CREATE_TICKET_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isCreateTicketModalOpen = isOpen;
  },
	OP_SET_TICKET_MODAL_ADDITIONAL_DATA(state, data) {
		state.operator_panel.ticketModalAdditionalData = data;
	},

  OP_SET_CONFIRM_MODAL(state, settings) {
    const defaultConfirmSettings = {
      open: false,
      title: i18n.t('modules.op.confirm_modal.attention'),
      text: i18n.t('modules.op.confirm_modal.confirm_action'),
      accept: () => console.log('accept confirm'),
    };

    const currentSettings = state.operator_panel.confirm;

    const options = settings.open
      ? { ...defaultConfirmSettings, ...settings }
      : { ...currentSettings, ...settings };

    state.operator_panel.confirm = options;
  },

  OP_SET_CLOSE_BTN_CLICKED(state, isClicked) {
    state.operator_panel.isCloseBtnClicked = isClicked;
  },

  OP_SET_SETTINGS(state, data) {
    state.operator_panel.settings = data;
  },

	OP_SET_SETTINGS_BY_KEY(state, data) {
		const { key, obj_key, value } = data;

		if(key) {
			const setting = state.operator_panel.settings.find(s => s.key === key);
			if(!setting) return

			// If obj_key does not contain '.', just set directly
			if (!obj_key.includes('.')) {
				setting[obj_key] = value;
			} else {
				// Split the key on '.', and navigate until we reach the final property
				const keys = obj_key.split('.');
				let target = setting;

				// Traverse down the object tree
				for (let i = 0; i < keys.length - 1; i++) {
					// If a nested key doesn't exist yet (e.g., setting["config"] could be undefined),
					// you may want to guard against that or create it, depending on your use case.
					target = target[keys[i]];
				}

				// The last key is where we set the value
				const lastKey = keys[keys.length - 1];
				target[lastKey] = value;
			}
		}
	},

  OP_SET_MODAL_LEAVE_CHAT_STATUS(state, statusObj) {
    state.operator_panel.modalLeaveChatStatus = statusObj;
  },

  OP_SET_SETTING_STYLES_CSS(state, value) {
    state.operator_panel.settingStylesCss = value;
  },
	OP_SET_SETTING_STYLES_JS(state, value) {
		state.operator_panel.settingStylesJS = value;
	},
  OP_SET_SETTING_CLIENT_FIELDS_JSON(state, value) {
    state.operator_panel.clientCardFields = value;
  },
	OP_SET_SETTING_FILES_JSON(state, value) {
		state.operator_panel.settingFiles = value;
  },
  OP_SET_DARK_THEME_COOKIE(state, value) {
    state.operator_panel.opDarkThemeCookie = value;
  },
  OP_SET_TICKET_LOGS_MODAL_OPEN(state, isOpen) {
    state.operator_panel.isTicketLogsModalOpen = isOpen;
  },
};

const actions = {
  async opGetSettings({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/settings/list/`;

    await axios
      .post(url)
      .then((response) => {
        const settings = response.data?.data?.settings || [];

        commit('OP_SET_SETTINGS', settings);

				//show/hide dialog grouping
				let dialogGroup = settings.find(s => s.key === 'dialog_group_by_subjects')
				commit('OP_SET_GROUP_BY_THEME', parseInt(dialogGroup?.value) || false)
				//end show/hide dialog grouping

				//Set message_delay for sending messages
				const message_delay = settings.find(s => s.key === 'message_delay')
				commit('OP_SET_MESSAGE_DELAY', parseInt(message_delay?.value) || false)
				//end Set message_delay for sending messages

				//Set extended dialog display in list
				const ticket_settings = settings.find(s => s.key === 'ticket_settings')
				commit('OP_SET_TICKET_SETTINGS', ticket_settings?.config || {})
				//end Set extended dialog display in list

				//Set customer_response_wait_time available to closing Dialog
				const customer_response_wait_time = settings.find(s => s.key === 'customer_response_wait_time')
				commit('OP_SET_CUSTOMER_RESPONSE_WAIT_TIME', parseInt(customer_response_wait_time?.value) || 0)
				//end Set customer_response_wait_time available to closing Dialog

				//set Ticket settings


      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
      });
  },

  axiosOpSettings ( { commit }, {request, list} ) {
    const token = Vue.$cookies.get('token_c');
    const setting = list === '' ? 'setting' : 'settings'

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/${setting}/${list}`,
          request,
          {
            headers: {
              'X-CSRF-TOKEN': token,
            },
          }
        )
        .then((response) => {
					if(list !== '') {
						commit('OP_SET_SETTINGS', response.data?.data?.settings || []);
					}
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async opGetLangList({ commit }) {
    const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/lang/list/`;

    const langList = await axios
      .get(url)
      .then((response) => {
        const langs = response.data?.data?.langs || [];

        const isDefault = (lang) => lang.default === 1;
        const defaultLang = langs.find(isDefault);
        const defaultLangId = defaultLang?.id || null;

        commit('OP_SET_DEFAULT_LANG', defaultLangId);

        return langs;
      })
      .catch((err) => {
        HELPERS.handleError([
          err,
          i18n.t('common.error'),
          i18n.t('common.error_get'),
        ]);
        return [];
      });

    commit('OP_SET_LANG_LIST', langList);
  },

  opSetGroupsPanelOpen({ commit }, isOpen) {
    commit('OP_SET_GROUPS_PANEL_OPEN', isOpen);
  },

  opSetRightSidebarOpen({ commit, dispatch }, isOpen) {
    commit('OP_SET_RIGHT_SIDEBAR_OPEN', isOpen);

    if (!isOpen) {
      //close history list panel
      dispatch("opSetRightSidebarHistoryPanelOpen", '');
			//close client fields panel
      dispatch("opSetRightSidebarClientFieldsOpen", false);

      //clear steps history
      dispatch('opCLearClientStepsHistory')
      dispatch('opSetRightSidebarStepsHistoryPanelOpen', false)
    }
  },

  opSetRightSidebarHistoryPanelOpen({ commit, dispatch, getters }, isOpen) {
    if (!isOpen) {
      dispatch("opSetRightSidebarHistoryItemId", '');
    }

		if(isOpen && getters.opIsRightBarStepsHistoryPanelOpen){
			commit('OP_SET_RIGHT_SIDEBAR_STEPS_HISTORY_PANEL_OPEN', false);
		}
    commit('OP_SET_RIGHT_SIDEBAR_HISTORY_PANEL_OPEN', isOpen);
  },
  opSetRightSidebarHistoryItemId({ commit, dispatch }, id) {
    if (id === undefined) {
      dispatch("opSetRightSidebarHistoryPanelOpen", false);
    }

    commit('OP_SET_RIGHT_SIDEBAR_HISTORY_ITEM_ID', id);
  },
  opSetRightSidebarStepsHistoryPanelOpen({ commit, dispatch, getters }, isOpen) {
		if(isOpen && getters.opIsRightBarHistoryPanelOpen) {
			dispatch("opSetRightSidebarHistoryPanelOpen", false);
		}

    commit('OP_SET_RIGHT_SIDEBAR_STEPS_HISTORY_PANEL_OPEN', isOpen);
  },
	opSetRightSidebarClientFieldsOpen({ commit, dispatch, getters }, isOpen) {
		if(
			isOpen && getters.opIsRightBarHistoryPanelOpen
		) {
			dispatch("opSetRightSidebarHistoryPanelOpen", false);
		}

		commit('OP_SET_RIGHT_SIDEBAR_CLIENT_FIELDS_OPEN', isOpen);
	},

  opSetTopSidebarOpen({ commit }, isOpen) {
    commit('OP_SET_TOP_SIDEBAR_OPEN', isOpen);
  },

  opSetUserSettingsOpen({ commit }, isOpen) {
    commit('OP_SET_USER_SETTINGS_OPEN', isOpen);
  },
  opSetHistoryPanelOpen({ commit }, isOpen) {
    commit('OP_SET_HISTORY_PANEL_OPEN', isOpen);
  },
  opSetHistoryPanelRightOpen({ commit }, isOpen) {
    commit('OP_SET_HISTORY_PANEL_RIGHT_OPEN', isOpen);
  },

  opSetAddChatUserModalOpen({ commit }, { open, type }) {
    commit('OP_SET_ADD_CHAT_USER_OPEN', { open, type });
  },
  opSetTransferChatUserModalOpen({ commit }, { open, data, withoutTimer = null }) {
		commit('OP_SET_TRANSFER_CHAT_USER_OPEN', { open, data, withoutTimer });
  },

  opSetNewRoomModalOpen({ commit }, isOpen) {
    commit('OP_SET_NEW_ROOM_MODAL_OPEN', isOpen);
  },

  opSetChatNewClientModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHAT_NEW_CLIENT_MODAL_OPEN', isOpen);
  },

	opSetCreateTicketModalOpen({ commit }, isOpen) {
    commit('OP_SET_CREATE_TICKET_MODAL_OPEN', isOpen);
  },
	opSetTicketModalAdditionalData({ commit }, data) {
    commit('OP_SET_TICKET_MODAL_ADDITIONAL_DATA', data);
  },

  opSetChatOpClientModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHAT_OP_CLIENT_MODAL_OPEN', isOpen);
  },
	opSetChatOpClientModalMode({ commit }, mode) {
		commit('OP_SET_CHAT_OP_CLIENT_MODAL_MODE', mode);
	},

  opSetChangeThemeModalOpen({ commit }, isOpen) {
    commit('OP_SET_CHANGE_THEME_MODAL_OPEN', isOpen);
  },

  opSetConfirmModal({ commit }, settings) {
    commit('OP_SET_CONFIRM_MODAL', settings);
  },

  opSetDialogSearchModalOpen({ commit }, isOpen) {
    commit('OP_SET_DIALOG_SEARCH_MODAL_OPEN', isOpen);
  },

  opSetCloseBtnClicked({ commit }, isClicked) {
    commit('OP_SET_CLOSE_BTN_CLICKED', isClicked);
  },

  opSetModalLeaveChatStatus({ commit }, statusObj) {
    commit("OP_SET_MODAL_LEAVE_CHAT_STATUS", statusObj);
  },

  /**
   * @name opSetSettingStyleCss
   * @description for change value in CODE EDITOR
   * @param commit
   * @param value
   */
  opSetSettingStyleCss ({ commit }, value) {
    commit("OP_SET_SETTING_STYLES_CSS", value);
  },
	/**
	 * @name opSetSettingStyleJS
	 * @description for change value in CODE EDITOR
	 * @param commit
	 * @param value
	 */
	opSetSettingStyleJS ({ commit }, value) {
		commit("OP_SET_SETTING_STYLES_JS", value);
	},
  /**
   * @name opSetSettingClientFieldsJson
   * @description for change value in CODE EDITOR
   * @param commit
   * @param value
   */
  opSetSettingClientFieldsJson ({ commit }, value) {
    commit("OP_SET_SETTING_CLIENT_FIELDS_JSON", value);
  },
	opSetSettingFilesJson ({ commit }, value) {
		commit("OP_SET_SETTING_FILES_JSON", value);
  },
	opSetDarkThemeCookie({commit}, value) {
		Vue.$cookies.set(
			'op_dark_theme_cookie',
			value,
			60 * 60 * 24 * 30
		);

		commit('OP_SET_DARK_THEME_COOKIE', value)
	},



	// CRUD actions
	async getAxiosSettingEntity(_, {entity, id}) {

		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/${entity}/${id}/`;

		return await axios
			.get(url)
			.then((response) => {
				return response?.data?.data?.entity
			})
			.catch((error) => {
				return error
			})
	},

	async saveAxiosSettingEntity({ dispatch }, {entity, data}) {
		const url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/ent/${entity}/`;

		dispatch('updateAjaxDialog', [true, true])

		return await axios
			.post( url, data )
			.then((response) => {
				dispatch('updateAjaxDialog', [false, false])
				return response
			})
			.catch((error) => {
				dispatch('updateAjaxDialog', [false, false])
				return error
			})
	},
	// end CRUD actions

	async opAxiosGetMacrosByBotId({ dispatch }, botId) {
		if(!botId) {
			dispatch('updateAjaxDialogError', [
				'',
				i18n.t('common.error'),
				i18n.t('modules.dialog.texts.interface.sections.macro.run_error_copilot'),
			]);
			return
		}

		let data;
		let url = `${process.env.VUE_APP_CONSTRUCTOR_URL}/kw/operator_panel/macros/list/${botId}/`;
		await dispatch('updateAjaxDialog', [true, true]);
		await axios
			.get(url)
			.then(
				(response) => {
					dispatch('updateAjaxDialog', [
						false,
						false,
					]);
					if (response.data && response.data.data) {
						data = response.data.data?.sections || [];
					}
				},
				(err) => {
					dispatch('updateAjaxDialogError', [
						err,
						i18n.t('common.error'),
						i18n.t('common.error_get'),
					]);
				}
			);
		return data;
	},


  opSetTicketLogsModalOpen({ commit }, isOpen) {
    commit('OP_SET_TICKET_LOGS_MODAL_OPEN', isOpen);
  },
};

const getters = {
  opIsGroupsPanelOpen: (state) => state.operator_panel.isGroupsPanelOpen,

  opIsRightBarOpen: (state) => state.operator_panel.isRightSideBarOpen,

  opIsRightBarHistoryPanelOpen: (state) => state.operator_panel.isRightSideBarHistoryPanelOpen,
  opIsRightBarStepsHistoryPanelOpen: (state) => state.operator_panel.isRightSideBarStepsHistoryPanelOpen,
  opIsRightBarHistoryItemId: (state) => state.operator_panel.isRightSideBarHistoryItemId,

	opIsRightBarClientFieldsOpen: (state) => state.operator_panel.isRightSideBarClientFieldsOpen,

  opIsTopBarOpen: (state) => state.operator_panel.isTopSideBarOpen,

  opIsUserSettingsOpen: (state) => state.operator_panel.isUserSettingsOpen,
  opIsHistoryPanelOpen: (state) => state.operator_panel.isHistoryPanelOpen,
  opIsHistoryPanelRightOpen: (state) => state.operator_panel.isHistoryPanelRightOpen,

  opIsLeftSideBarOpen: (state, getters) => {
    const { opIsUserSettingsOpen, opIsGroupsPanelOpen } = getters;
    return opIsUserSettingsOpen || opIsGroupsPanelOpen;
  },

  opIsAddChatUserModalOpen: (state) => {
    return state.operator_panel.isAddChatUserModalOpen;
  },

  opIsTransferChatUserModalOpen: (state) => {
	  return state.operator_panel.isTransferChatUserModalOpen;
  },

  opIsAddChangeThemeModalOpen: (state) => {
    return state.operator_panel.isAddChangeThemeModalOpen;
  },

  opIsNewRoomModalOpen: (state) => {
    return state.operator_panel.isNewRoomModalOpen;
  },

  opIsChatNewClientModalOpen: (state) => {
    return state.operator_panel.isChatNewClientModalOpen;
  },

	opIsCreateTicketModalOpen: (state) => {
    return state.operator_panel.isCreateTicketModalOpen;
  },
	opTicketModalAdditionalData: (state) => {
    return state.operator_panel.ticketModalAdditionalData;
  },

  opIsChatOpClientModalOpen: (state) => {
    return state.operator_panel.isChatOpClientModalOpen;
  },

	opIsChatOpClientModalMode: (state) => {
    return state.operator_panel.isChatOpClientModalMode;
  },

  opIsDialogSearchModalOpen: (state) => {
    return state.operator_panel.isDialogSearchModalOpen;
  },

  opConfirmModal: (state) => state.operator_panel.confirm,

  opIsCloseBtnClicked: (state) => state.operator_panel.isCloseBtnClicked,

  opIsSettings: (state) => state.operator_panel.settings,

  opModalLeaveChatStatus: (state) => {
    return state.operator_panel.modalLeaveChatStatus;
  },

  opGetSettingStylesCss: (state) => {
    return state.operator_panel.settingStylesCss;
  },
	opGetSettingStylesJS: (state) => {
		return state.operator_panel.settingStylesJS;
	},
  opGetSettingClientFieldsJson: (state) => {
    return state.operator_panel.clientCardFields;
  },
	opGetSettingFilesJson: (state) => {
		return state.operator_panel.settingFiles;
	},

  opGetLangList: (state) => {
    return state.operator_panel.langList
  },

	opGetDarkThemeCookie: (state) => {
		return state.operator_panel.opDarkThemeCookie
	},

	opGetTicketLogsModalOpen: (state) => {
		return state.operator_panel.isTicketLogsModalOpen
	},
};

export default {
  state,
  mutations,
  actions,
  getters,
};
